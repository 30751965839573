body {
  font-family: 'Nunito Sans', 'Helvetica Neue', Arial, sans-serif !important;
  overflow-x: hidden;
  background-color: #FFF;

  &.noScroll,
  &.bx--body--with-modal-open {
    overflow: hidden;
  }

  .opaque {
    opacity: 1 !important;
  }

  .priceTooltipOverlay {
    z-index: 9000;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #F3F2F2;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #D4D4D4;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #D4D4D4; 
  }
}

.react-tooltip {
  background-color: #FFF !important;
  opacity: 1 !important;
  z-index: 1;
}

.error {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  color: #DA1E28;
}

input,
.bx--input,
.bx--text-input {
  &:focus, &:active {
    border-radius: 5px;
    outline: 1px solid #707070 !important;
    outline-offset: -1px !important;
  }
}

button.bx--btn {
  &.no-border {
    border: 0;
  }
  &:disabled {
    opacity: 0.7;
    background-color: #c6c6c6;
  }
  .bx--inline-loading__animation {
    margin: 0 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bx--loading {
    position: absolute;
    left: 10px;
    top: calc(50% - 0.5rem);
    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 0 !important;
  padding: 0;
  cursor: pointer;

  .green, .red, .blue {
    svg {
      margin-right: 5px;
    }
  }
}

.bx--btn.bx--btn--ghost {
  &.red {
    &:not(:disabled) {
      &:hover {
        background-color: #FCE8E7;
      }
      &:focus {
        box-shadow: inset 0 0 0 1px #DA1E28, inset 0 0 0 2px #ffffff
      }
    }
    border-color: #DA1E28;
    color: #DA1E28;
  }
  &.blue {
    &:not(:disabled) {
      &:hover {
        background-color: #EEF5FF;
      }
      &:focus {
        box-shadow: inset 0 0 0 1px #0f62fe, inset 0 0 0 2px #ffffff
      }
    }
    border-color: #1F59A2;
    color: #1F59A2;
  }
  &.green {
    &:not(:disabled) {
      &:hover {
        background-color: #E2F1E2;
      }
    }
    border-color: #68A765;
    color: #498246;
  }
  background-color: #FFF;
}

.bx--btn.bx--btn--primary {
  &.green {
    &:not(:disabled) {
      &:hover {
        background-color: #E2F1E2;
      }
    }
    color: #3A3A3A;
    background-color: #F4F9F4;
  }
  &.red {
    &:not(:disabled) {
      &:hover {
        background-color: #BE2317;
      }
    }
    .bx--inline-loading--error {
      fill: #FFF;
    }
    .bx--inline-loading__checkmark-container {
      fill: #E3F1E3;
    }
    color: #FFF;
    border-color: #DA1E28;
    background-color: #DA1E28;
  }
  &.blue {

  }
}

button {
  color: #3a3a3a;
}

a {
  &:hover {
    color: #1F59A2;
  }
  color: #3a3a3a;
  text-decoration: none;
}

.grid-container {
  max-width: 1920px;
  width: 100%;
  padding: 0 45px;
  box-sizing: border-box;
  margin: 0 auto;
}

.bx--modal {
  .bx--modal-header {
    height: 48px;
    padding: 0 0 0 15px;
    background-color: #F3F2F2;
    font-size: 1.25rem;
    color: #3A3A3A;

    .title, h4 {
      line-height: 48px;
      font-weight: 600;
    }

    .bx--modal-close {
      color: #FFF;
      background-color: #41618A;
      svg {
        fill: #FFF;
        transform: scale(1.25);
      }
    }
  }

  .bx--form-item {
    .bx--label {
      font-size: 0.9rem;
      color: #3A3A3A;
    }
  }

  .bx--modal-container {
    background-color: #FFF;
  }

  .bx--modal-footer {
    width: 100%;
    padding: 0 10px;
    margin: 15px auto 0;

    .bx--btn {
      height: 40px !important;
      min-height: 0;
      font-size: 1rem;
      line-height: 1.15rem;
      margin: 0 5px !important;

      svg {
        height: 16px;
      }
    }
  }

  .bx--modal-container--xs {
    max-height: 75vh;
  }

  .bx--modal-container--sm {
    max-width: 450px;
  }
}

button {
  .bx--loading {
    position: relative;
    margin: 0;
    left: -10px;

    svg {
      margin: 0 !important;
    }
  }
}

iframe[name='JSD widget'] {
  max-height: calc(100% - 60px);
  margin-bottom: 60px;
}

@media screen and (max-width: 1336px) {
  .grid-container {
    padding: 0 32px;
  }
}

@media screen and (max-width: 1024px) {
  .grid-container {
    padding: 0 16px;
  }
}
